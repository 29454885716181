$.fn.selectCustom = function() {
	return this.each(function(index) {
		const $select = $(this)
		const $parent = $(this).parents('.select-custom')
		const $filtro  = document.createElement('input')
		let $dropdown,$options, $output;

		$parent.addClass('select-custom-ready')
		$filtro.type = 'text';
		$filtro.className = 'form-control filtro';
		$filtro.ariaLabel = 'Filtrar por:'

		setFiltro($filtro)

		$select.on('refresh', function() {
			render();
		});


		render();

		function render() {
			$parent.find('.dropdown').remove();
			template();
		}

		function template() {
			let $id = $select.attr('id') || `custom-select-${index}`
			let template = `<div class="dropdown">
				<button
				 class="form-control output"
				 data-toggle="dropdown"
				 aria-expanded="false"
				 id="${$id}-label">${$select.attr('placeholder') || 'Selecione'}</button>
				<div class="dropdown-menu">
					<ul
					 class="drop-items"
					 role="listbox"
					 id="${$id}-listbox"
					 aria-labelledby="${$id}-label"></ul>
				</div>
			</div>`
			let options = $select.find('option')

			$parent.append(template)

			$output 	= $parent.find('.output')
			$dropdown 	= $parent.find('.drop-items')


			options.each(function(i) {
				let $option = $(this)
				let value = $option.attr('value') || $option.html();
				let text = $option.html()
				let li, $li

				li = document.createElement('li')
				$li = $(li)

				if(this.disabled) {
					$li
						.addClass('disabled')
				}

				if(this.selected) {
					$li.addClass('selected')
					$select.val(value)
				}

				li.role = 'option'
				li.id = `${$id}-option-${i}`
				$li.html(text);
				$li.click(function() {
					$output.html(text)
					$select.val(value)

					$li.addClass('selected')
					$li.siblings('li').removeClass('selected')
				})

				$dropdown.append($li)
			})

			$options = $parent.find('.drop-items li')

			if($select[0].hasAttribute('filter')) {
				let container = $parent.find('.dropdown');

				container.on('show.bs.dropdown', function() {
					$dropdown[0].insertAdjacentElement('beforebegin', $filtro);
				})

				container.on('shown.bs.dropdown', function() {
					$filtro.focus()
				})

				container.on('hide.bs.dropdown', function() {
					$filtro.value = ''
					$($filtro).remove()
					$options.removeClass('hide')
				})
			}
		}

		function setFiltro(input) {
			input.addEventListener('keyup', e=> {
				e.preventDefault()
				let key = normalizaString(input.value)

				$options.each(function() {
					let $this = $(this);
					let text  = normalizaString(this.innerHTML)

					if(text.indexOf(key) < 0) {
						$this.addClass('hide')
					}else {
						$this.removeClass('hide')
					}
				});
			})
		}

		function normalizaString(string) {
			return string.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
		}
	})
}

$('.select-custom select').selectCustom();
