(function() {
	const btn  = document.querySelector('.mobile__controls .btn');
	const menu = document.querySelector('.topo__main');
	const body = document.body;
	const topo = document.querySelector('.topo');
	const backdrop = document.createElement('div');
	const activeClass = 'topo__main--shown';
	const hideClass = 'topo__main--hide';

	backdrop.classList.add('backdrop');

	btn.addEventListener('click', e=> {
		e.preventDefault();

		btn.setAttribute('aria-expanded', 'true');
		topo.appendChild(backdrop);
		menu.classList.add(activeClass);
		body.classList.add(activeClass);

		backdrop.addEventListener('click', function() {
			btn.setAttribute('aria-expanded', 'false');
			menu.classList.remove(activeClass);
			body.classList.add(hideClass);
			backdrop.classList.add(hideClass);

			backdrop.addEventListener('animationend', closeMobileControls, false)
		}, false);
	})

	function closeMobileControls() {

		body.classList.remove(hideClass);
		body.classList.remove(activeClass);
		backdrop.classList.remove(hideClass);
		topo.removeChild(backdrop)

		reset();
	}

	function reset() {
		backdrop.removeEventListener('animationend', closeMobileControls, false)
	}
})();
