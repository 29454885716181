(function() {
	const observer = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			if(entry.isIntersecting) {
				let bg = entry.target.dataset.lazybg;

				bg = bg ? `url(${JSON.stringify(bg)})` : 'none';

				entry.target.style.setProperty('--lazybg', bg);
				entry.target.removeAttribute('data-lazybg');

				observer.unobserve(entry.target);
			}
		});
	});
	const lazyBgs = document.querySelectorAll('[data-lazybg]');

	lazyBgs.forEach(el => observer.observe(el));
})();
