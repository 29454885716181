(function() {
	$('[data-video]').each(function() {
		var url 	= $(this).data('video');
		var target 	= $(this).data('target');
		var modal 	= $(target);
		var videoID = youtube_parser(url);
		var embed = `<iframe type="text/html" src="https://www.youtube.com/embed/${videoID}?autoplay=1&playsinline=1"  frameborder="0" allowfullscreen></iframe>`
		var container = modal.find('.embed-responsive')

		$(this).click(function() {
			container.html(embed)
		});
	});

	function youtube_parser(url) {
		var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
		var match = url.match(regExp);
		return (match && match[1].length == 11) ? match[1] : false;
	}

	$('.modal__youtube').on('hide.bs.modal', function() {
		$(this).find('.embed-responsive').html('')
	})
})();
