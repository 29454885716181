(function() {

	owlSync(
		$('#album'),
		$('#album-thumbs [data-slide-to]')
	)

	owlSync(
		$('#carousel-empresa'),
		$('#quem-somos [data-slide-to]')
	)

	function owlSync(carousel, thumbs) {
		var album = carousel;
		var thumbnails = thumbs;

		album.on('slide.bs.carousel', function (e) {
			thumbnails.removeClass('active');
			$(thumbnails[e.to]).addClass('active');
		})
	}
})();
