window.addEventListener('load', function () {
	const observer = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				CounterUP(entry.target);

				observer.unobserve(entry.target);
			}
		})
			, {
			threshold: 0.75
		}
	});

	let els = document.querySelectorAll('[data-counterup]');

	els.forEach(el => {
		el.innerHTML = 0 + el.dataset.suffix;
		observer.observe(el);
	})

});

function CounterUP(element) {
	let el = $(element);
	let range = el.data('counterup') || 0;
	let preffix = el.data('preffix') || '';
	let suffix = el.data('suffix') || '';
	let duration = 2000; // 2s
	let inicio = 0;
	let minTime = 50;
	let stepTime = Math.abs(Math.floor(duration / range));
	let startTime = new Date().getTime();
	stepTime = Math.max(stepTime, minTime)
	let endTime = startTime + duration;
	let interval = setInterval(contador, stepTime);

	element.innerHTML = `${preffix}${inicio}-${suffix}`;
	el.parents('.experiencia__counter').addClass('active');

	function contador() {
		let now = new Date().getTime();
		let remanescente = Math.max((endTime - now) / duration, 0);
		let valor = Math.round(range - (remanescente * range));

		element.innerHTML = `${preffix}${valor}${suffix}`;

		if (valor === range) {
			clearInterval(interval);
		}
	}

}
